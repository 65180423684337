import { FontFaces } from './FontFace';

import Montserrat100 from '../../assets/fonts/Montserrat/Montserrat-100.ttf';
import Montserrat200 from '../../assets/fonts/Montserrat/Montserrat-200.ttf';
import Montserrat300 from '../../assets/fonts/Montserrat/Montserrat-300.ttf';
import Montserrat400 from '../../assets/fonts/Montserrat/Montserrat-400.ttf';
import Montserrat500 from '../../assets/fonts/Montserrat/Montserrat-500.ttf';
import Montserrat600 from '../../assets/fonts/Montserrat/Montserrat-600.ttf';
import Montserrat700 from '../../assets/fonts/Montserrat/Montserrat-700.ttf';
import Montserrat900 from '../../assets/fonts/Montserrat/Montserrat-900.ttf';

import Roboto100 from '../../assets/fonts/Roboto/Roboto-Thin.ttf';
import Roboto100Italic from '../../assets/fonts/Roboto/Roboto-ThinItalic.ttf';
import Roboto300 from '../../assets/fonts/Roboto/Roboto-Light.ttf';
import Roboto300Italic from '../../assets/fonts/Roboto/Roboto-LightItalic.ttf';
import Roboto400 from '../../assets/fonts/Roboto/Roboto-Regular.ttf';
import Roboto400Italic from '../../assets/fonts/Roboto/Roboto-Italic.ttf';
import Roboto500 from '../../assets/fonts/Roboto/Roboto-Medium.ttf';
import Roboto500Italic from '../../assets/fonts/Roboto/Roboto-MediumItalic.ttf';
import Roboto700 from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import Roboto700Italic from '../../assets/fonts/Roboto/Roboto-BoldItalic.ttf';
import Roboto900 from '../../assets/fonts/Roboto/Roboto-Black.ttf';
import Roboto900Italic from '../../assets/fonts/Roboto/Roboto-BlackItalic.ttf';

export const Montserrat = FontFaces.create(
  [
    { url: Montserrat100, weight: 100 },
    { url: Montserrat200, weight: 200 },
    { url: Montserrat300, weight: 300 },
    { url: Montserrat400, weight: 400 },
    { url: Montserrat500, weight: 500 },
    { url: Montserrat600, weight: 600 },
    { url: Montserrat700, weight: 700 },
    { url: Montserrat900, weight: 900 },
  ],
  { name: 'Montserrat' },
);

export const Roboto = FontFaces.create(
  [
    { url: Roboto100, weight: 100 },
    { url: Roboto300, weight: 300 },
    { url: Roboto400, weight: 400 },
    { url: Roboto500, weight: 500 },
    { url: Roboto700, weight: 700 },
    { url: Roboto900, weight: 900 },
  ],
  { name: 'Roboto' },
);

export const RobotoItalic = FontFaces.create(
  [
    { url: Roboto100Italic, weight: 100 },
    { url: Roboto300Italic, weight: 300 },
    { url: Roboto400Italic, weight: 400 },
    { url: Roboto500Italic, weight: 500 },
    { url: Roboto700Italic, weight: 700 },
    { url: Roboto900Italic, weight: 900 },
  ],
  { name: 'Roboto' },
);
