import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import reset from 'styled-reset-advanced';

import { Montserrat, Roboto } from './Fonts';
import theme from '../../theme';

const Wrapper = styled.div`
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif;
  *, *:before, *:after { user-select: none; };
`;

const Global = createGlobalStyle`
  ${reset};
`;

export default ({ children }) => (
    <ThemeProvider theme={theme}>
      <>
        <Roboto />
        <Montserrat />
        <Global />
        <Wrapper>
          {children}
        </Wrapper>
      </>
    </ThemeProvider>
);
