/**
 * Theme
 * -----
 */

import color from 'tinycolor2';
import { rem } from './utils';

const breakpoints = [
  rem('380px'), rem('768px'), // mobile
  rem('1024px'), rem('1280px'), rem('1680px'), rem('1920px'), // desktop
  rem('768px'), rem('992px'), rem('1200px'), // desktop min-width
];

const Sitewidth = '1200px';

// color config
const primary = '#49A6DB';
const secondary = 'hsl(195, 100%, 45%)';
const white = 'hsl(0, 0%, 100%)';
const black = 'hsl(0, 0%, 0%)';

// color mixins
export const colors = {
  primary: {
    base: primary,
    shade: [
      color(primary).setAlpha(0.85).toHslString(),
      color(primary).setAlpha(0.60).toHslString(),
      color(primary).setAlpha(0.50).toHslString(),
      color(primary).setAlpha(0.30).toHslString(),
      color(primary).setAlpha(0.10).toHslString(),
      color(primary).setAlpha(0.05).toHslString(),
      color(primary).setAlpha(0.025).toHslString(),
    ],
    gradient: `linear-gradient(to right,
      ${color(primary).darken(0.3).spin(-8).toHslString()},
      ${color(primary).lighten(0.3).spin(8).toHslString()}
    )`,
  },
  secondary: {
    base: secondary,
    shade: [
      color(secondary).setAlpha(0.85).toHslString(),
      color(secondary).setAlpha(0.60).toHslString(),
      color(secondary).setAlpha(0.50).toHslString(),
      color(secondary).setAlpha(0.30).toHslString(),
      color(secondary).setAlpha(0.10).toHslString(),
      color(secondary).setAlpha(0.05).toHslString(),
      color(secondary).setAlpha(0.025).toHslString(),
    ],
  },
  white: {
    base: white,
    shade: [
      color(white).setAlpha(0.85).toHslString(),
      color(white).setAlpha(0.60).toHslString(),
      color(white).setAlpha(0.50).toHslString(),
      color(white).setAlpha(0.30).toHslString(),
      color(white).setAlpha(0.10).toHslString(),
      color(white).setAlpha(0.05).toHslString(),
      color(white).setAlpha(0.025).toHslString(),
    ],
  },
  black: {
    base: black,
    shade: [
      color(black).setAlpha(0.85).toHslString(),
      color(black).setAlpha(0.60).toHslString(),
      color(black).setAlpha(0.50).toHslString(),
      color(black).setAlpha(0.30).toHslString(),
      color(black).setAlpha(0.10).toHslString(),
      color(black).setAlpha(0.05).toHslString(),
      color(black).setAlpha(0.025).toHslString(),
    ],
  },
};

const spaces = [
  rem(3), rem(5), rem(8), rem(13), rem(21), rem(34),
  rem(55), rem(89), rem(144), rem(233), rem(377), rem(610), rem(120),
];

const fontSizes = [
  rem(14), rem(16), rem(18), rem(20), rem(22), rem(24), rem(26),
  rem(32), rem(34), rem(38), rem(48), rem(90),
];

const fontWeights = {
  light: 300,
  regular: 400,
  semi: 600,
  bold: 700,
  heavy: 900,
};

const radii = [
  rem(0), rem(2), rem(4), rem(8), rem(16), rem(32),
];

const theme = {
  breakpoints,
  Sitewidth,
  colors,
  spaces,
  fontSizes,
  fontWeights,
  radii,
};

export default theme;
