import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Page from '../components/Page';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    padding: 10px;
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary.base};
`;

const NotFoundPage = () => (
  <Page>
    <Wrapper>
      <h1>404 NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
      <StyledLink to="/">Go home?</StyledLink>
    </Wrapper>
  </Page>
);

export default NotFoundPage;
